:root {
  --bs-primary-rgb: 190, 78, 56;
  --bs-secondary-rgb: 8, 103, 136;
  --bs-white-rgb: 255, 255, 255;
  --bs-body-font-family: Josefin Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1.1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.4;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  background-color: currentColor;
  border: 0;
  margin: 1rem 0;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: #212529;
  margin-top: 0;
  margin-bottom: .5rem;
  font-family: Saira Extra Condensed, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.775rem + 6.3vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 6.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.475rem + 2.7vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 3.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.3825rem + 1.59vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 2.575rem;
  }
}

h4, .h4 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 2rem;
  }
}

h5, .h5 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h5, .h5 {
    font-size: 1.5rem;
  }
}

h6, .h6 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

ul ul {
  margin-bottom: 0;
}

strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #be4e38;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img {
  vertical-align: middle;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  -webkit-appearance: button;
  margin: 0;
}

button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.container-fluid {
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.collapse:not(.show) {
  display: none;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  min-width: 10rem;
  margin: 0;
  padding: .5rem 0;
  font-size: 1.1rem;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: .125rem;
  top: 100%;
  left: 0;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropdown-divider {
  border-top: 1px solid #00000026;
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  width: 100%;
  padding: .25rem 1rem;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #be4e38;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1rem;
  font-size: .9625rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1rem;
  display: block;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: #00000026;
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: #ffffff26;
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #be4e38;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: #00000026;
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  color: #be4e38;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #983e2d;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
  position: relative;
}

.navbar > .container-fluid {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3075rem;
  padding-bottom: .3075rem;
  font-size: calc(1.2625rem + .15vw);
  text-decoration: none;
}

@media (width >= 1200px) {
  .navbar-brand {
    font-size: 1.375rem;
  }
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: calc(1.2625rem + .15vw);
  line-height: 1;
  transition: box-shadow .15s ease-in-out;
}

@media (width >= 1200px) {
  .navbar-toggler {
    font-size: 1.375rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  outline: 0;
  text-decoration: none;
  box-shadow: 0 0 0 .25rem;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff8c;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff8c;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.toast {
  pointer-events: auto;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  width: 250px;
  max-width: 100%;
  font-size: .875rem;
  box-shadow: 0 .5rem 1rem #00000026;
}

.toast:not(.show) {
  display: none;
}

.toast-body {
  word-wrap: break-word;
  padding: .75rem;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.position-fixed {
  position: fixed !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.end-0 {
  right: 0 !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.m-auto {
  margin: auto !important;
}

section.resume-section.text h6, section.resume-section.text .h6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

section.resume-section.text .subheading + h5, section.resume-section.text .subheading + .h5 {
  margin-top: .25rem !important;
}

.mt-4, section.resume-section.text h5, section.resume-section.text .h5 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-0, section.resume-section.text h5:has( + .subheading-minor,  + .subheading), section.resume-section.text .h5:has( + .subheading-minor,  + .subheading) {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5, #volunteering.resume-section .resume-section-content h2, #volunteering.resume-section .resume-section-content .h2 {
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

section.resume-section .resume-section-content > ul > li {
  padding-bottom: .5rem !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

#volunteering.resume-section .resume-section-content > ul > li span {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

@media (width >= 768px) {
  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
}

@media (width >= 992px) {
  .d-lg-block {
    display: block !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .text-lg-end {
    text-align: right !important;
  }
}

@media (width >= 1400px) {
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
}

body {
  color: #495057;
  letter-spacing: -.4px;
  background-color: #fcfbfa;
  padding-top: 1.6875rem;
}

@media (width >= 992px) {
  body {
    padding-top: 0;
    padding-left: 16rem;
  }
}

.img-profile {
  width: 11rem;
  height: 11rem;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1.05;
}

p.lead {
  font-size: 1.15rem;
  font-weight: 400;
}

.subheading {
  text-transform: uppercase;
  letter-spacing: normal;
  padding-top: .125rem;
  font-family: Saira Extra Condensed, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  line-height: 1.05;
}

h5 + .subheading, .h5 + .subheading {
  font-size: calc(1.2735rem + .282vw);
}

@media (width >= 1200px) {
  h5 + .subheading, .h5 + .subheading {
    font-size: 1.485rem;
  }
}

h4 + .subheading, .h4 + .subheading {
  font-size: calc(1.29rem + .48vw);
}

@media (width >= 1200px) {
  h4 + .subheading, .h4 + .subheading {
    font-size: 1.65rem;
  }
}

.subheading-minor {
  text-transform: uppercase;
  letter-spacing: normal;
  padding-top: .125rem;
  font-family: Saira Extra Condensed, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  line-height: 1.1;
}

h4 + .subheading-minor, .h4 + .subheading-minor {
  font-size: calc(1.2735rem + .282vw);
}

@media (width >= 1200px) {
  h4 + .subheading-minor, .h4 + .subheading-minor {
    font-size: 1.485rem;
  }
}

p + h4, p + .h4 {
  padding-top: 12px;
}

a {
  text-decoration: none;
}

a:hover {
  color: #086788;
}

@media (width <= 575.98px) {
  hr {
    width: 45%;
  }
}

#sidenav {
  padding-left: 1rem;
  padding-right: 1rem;
}

#sidenav .navbar-nav .nav-item .nav-link {
  letter-spacing: .05rem;
  text-transform: uppercase;
  font-weight: 700;
}

#sidenav .navbar-toggler:focus {
  outline-color: #e0a296;
}

@media (width >= 992px) {
  #sidenav {
    text-align: center;
    flex-direction: column;
    width: 16rem;
    height: 100vh;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  #sidenav .navbar-brand {
    margin: auto auto 0;
    padding: .5rem;
    display: flex;
  }

  #sidenav .navbar-brand .img-profile {
    border: .5rem solid #fff3;
  }

  #sidenav .navbar-collapse {
    flex-grow: 0;
    align-items: flex-start;
    width: 100%;
    margin-bottom: auto;
    display: flex;
  }

  #sidenav .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  #sidenav .navbar-collapse .navbar-nav .nav-item, #sidenav .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: block;
  }
}

.social-icons .social-icon {
  background-color: #343a40;
  border-width: 0;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin-right: 1rem;
  display: inline-flex;
}

.social-icons .social-icon svg {
  fill: #fff;
}

.social-icons .social-icon__github {
  background-color: #fff;
}

.social-icons .social-icon__github:hover {
  background-color: #be4e38 !important;
}

.social-icons .social-icon__github svg {
  fill: #343a40;
  width: 60px;
  height: 60px;
}

.social-icons .social-icon__linkedin {
  padding-top: 2px;
}

.social-icons .social-icon__linkedin svg {
  width: 40px;
  height: 40px;
}

.social-icons .social-icon__linkedin svg:hover {
  fill: #be4e38 !important;
}

.social-icons .social-icon:last-child {
  margin-right: 0;
}

.dev-icons {
  font-size: 3rem;
}

.bullet, .bullet-user-pen, .bullet-pen-nib, .bullet-globe, .bullet-person-military-pointing, .bullet-hand-point-up, .bullet-people-group, .bullet-crown, .bullet-bullhorn, .bullet-scroll, .bullet-book, .bullet-certificate, .bullet-flag-checkered, .bullet-graduation-cap, .bullet-trophy {
  list-style: none;
  display: list-item;
  position: relative;
}

.bullet:before, .bullet-user-pen:before, .bullet-pen-nib:before, .bullet-globe:before, .bullet-person-military-pointing:before, .bullet-hand-point-up:before, .bullet-people-group:before, .bullet-crown:before, .bullet-bullhorn:before, .bullet-scroll:before, .bullet-book:before, .bullet-certificate:before, .bullet-flag-checkered:before, .bullet-graduation-cap:before, .bullet-trophy:before {
  text-align: center;
  line-height: inherit;
  content: "";
  background: #086788;
  width: 1.3rem;
  height: 1.3rem;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: -28px;
}

.bullet-trophy:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M400%200H176c-26.5%200-48.1%2021.8-47.1%2048.2.2%205.3.4%2010.6.7%2015.8H24C10.7%2064%200%2074.7%200%2088c0%2092.6%2033.5%20157%2078.5%20200.7%2044.3%2043.1%2098.3%2064.8%20138.1%2075.8%2023.4%206.5%2039.4%2026%2039.4%2045.6%200%2020.9-17%2037.9-37.9%2037.9H192c-17.7%200-32%2014.3-32%2032s14.3%2032%2032%2032h192c17.7%200%2032-14.3%2032-32s-14.3-32-32-32h-26.1c-20.9%200-37.9-17-37.9-37.9%200-19.6%2015.9-39.2%2039.4-45.6%2039.9-11%2093.9-32.7%20138.2-75.8C542.5%20245%20576%20180.6%20576%2088c0-13.3-10.7-24-24-24H446.4c.3-5.2.5-10.4.7-15.8C448.1%2021.8%20426.5%200%20400%200zM48.9%20112h84.4c9.1%2090.1%2029.2%20150.3%2051.9%20190.6-24.9-11-50.8-26.5-73.2-48.3-32-31.1-58-76-63-142.3zm415.2%20142.3c-22.4%2021.8-48.3%2037.3-73.2%2048.3%2022.7-40.3%2042.8-100.5%2051.9-190.6h84.4c-5.1%2066.3-31.1%20111.2-63%20142.3z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M400%200H176c-26.5%200-48.1%2021.8-47.1%2048.2.2%205.3.4%2010.6.7%2015.8H24C10.7%2064%200%2074.7%200%2088c0%2092.6%2033.5%20157%2078.5%20200.7%2044.3%2043.1%2098.3%2064.8%20138.1%2075.8%2023.4%206.5%2039.4%2026%2039.4%2045.6%200%2020.9-17%2037.9-37.9%2037.9H192c-17.7%200-32%2014.3-32%2032s14.3%2032%2032%2032h192c17.7%200%2032-14.3%2032-32s-14.3-32-32-32h-26.1c-20.9%200-37.9-17-37.9-37.9%200-19.6%2015.9-39.2%2039.4-45.6%2039.9-11%2093.9-32.7%20138.2-75.8C542.5%20245%20576%20180.6%20576%2088c0-13.3-10.7-24-24-24H446.4c.3-5.2.5-10.4.7-15.8C448.1%2021.8%20426.5%200%20400%200zM48.9%20112h84.4c9.1%2090.1%2029.2%20150.3%2051.9%20190.6-24.9-11-50.8-26.5-73.2-48.3-32-31.1-58-76-63-142.3zm415.2%20142.3c-22.4%2021.8-48.3%2037.3-73.2%2048.3%2022.7-40.3%2042.8-100.5%2051.9-190.6h84.4c-5.1%2066.3-31.1%20111.2-63%20142.3z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-graduation-cap:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M320%2032c-8.1%200-16.1%201.4-23.7%204.1L15.8%20137.4C6.3%20140.9%200%20149.9%200%20160s6.3%2019.1%2015.8%2022.6l57.9%2020.9C57.3%20229.3%2048%20259.8%2048%20291.9V320c0%2028.4-10.8%2057.7-22.3%2080.8-6.5%2013-13.9%2025.8-22.5%2037.6-3.2%204.3-4.1%209.9-2.3%2015s6%208.9%2011.2%2010.2l64%2016c4.2%201.1%208.7.3%2012.4-2s6.3-6.1%207.1-10.4c8.6-42.8%204.3-81.2-2.1-108.7-3.2-14.2-7.5-28.7-13.5-42v-24.6c0-30.2%2010.2-58.7%2027.9-81.5%2012.9-15.5%2029.6-28%2049.2-35.7l157-61.7c8.2-3.2%2017.5.8%2020.7%209s-.8%2017.5-9%2020.7l-157%2061.7c-12.4%204.9-23.3%2012.4-32.2%2021.6l159.6%2057.6c7.6%202.7%2015.6%204.1%2023.7%204.1s16.1-1.4%2023.7-4.1l280.6-101c9.5-3.4%2015.8-12.5%2015.8-22.6s-6.3-19.1-15.8-22.6L343.7%2036.1c-7.6-2.7-15.6-4.1-23.7-4.1zM128%20408c0%2035.3%2086%2072%20192%2072s192-36.7%20192-72l-15.3-145.4L354.5%20314c-11.1%204-22.8%206-34.5%206s-23.5-2-34.5-6l-142.2-51.4L128%20408z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M320%2032c-8.1%200-16.1%201.4-23.7%204.1L15.8%20137.4C6.3%20140.9%200%20149.9%200%20160s6.3%2019.1%2015.8%2022.6l57.9%2020.9C57.3%20229.3%2048%20259.8%2048%20291.9V320c0%2028.4-10.8%2057.7-22.3%2080.8-6.5%2013-13.9%2025.8-22.5%2037.6-3.2%204.3-4.1%209.9-2.3%2015s6%208.9%2011.2%2010.2l64%2016c4.2%201.1%208.7.3%2012.4-2s6.3-6.1%207.1-10.4c8.6-42.8%204.3-81.2-2.1-108.7-3.2-14.2-7.5-28.7-13.5-42v-24.6c0-30.2%2010.2-58.7%2027.9-81.5%2012.9-15.5%2029.6-28%2049.2-35.7l157-61.7c8.2-3.2%2017.5.8%2020.7%209s-.8%2017.5-9%2020.7l-157%2061.7c-12.4%204.9-23.3%2012.4-32.2%2021.6l159.6%2057.6c7.6%202.7%2015.6%204.1%2023.7%204.1s16.1-1.4%2023.7-4.1l280.6-101c9.5-3.4%2015.8-12.5%2015.8-22.6s-6.3-19.1-15.8-22.6L343.7%2036.1c-7.6-2.7-15.6-4.1-23.7-4.1zM128%20408c0%2035.3%2086%2072%20192%2072s192-36.7%20192-72l-15.3-145.4L354.5%20314c-11.1%204-22.8%206-34.5%206s-23.5-2-34.5-6l-142.2-51.4L128%20408z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-flag-checkered:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M32%200c17.7%200%2032%2014.3%2032%2032v16l69-17.2c38.1-9.5%2078.3-5.1%20113.5%2012.5%2046.3%2023.2%20100.8%2023.2%20147.1%200l9.6-4.8c20.6-10.4%2044.8%204.6%2044.8%2027.6v279.7c0%2013.3-8.3%2025.3-20.8%2030l-34.7%2013c-46.2%2017.3-97.6%2014.6-141.7-7.4-37.9-19-81.3-23.7-122.5-13.4L64%20384v96c0%2017.7-14.3%2032-32%2032S0%20497.7%200%20480V32C0%2014.3%2014.3%200%2032%200zm32%20187.1%2064-13.9v65.5l-64%2013.9V318l48.8-12.2c5.1-1.3%2010.1-2.4%2015.2-3.3v-63.9l38.9-8.4c8.3-1.8%2016.7-2.5%2025.1-2.1v-64c13.6.4%2027.2%202.6%2040.4%206.4l23.6%206.9v66.7l-41.7-12.3c-7.3-2.1-14.8-3.4-22.3-3.8v71.4c21.8%201.9%2043.3%206.7%2064%2014.4V244l22.7%206.7c13.5%204%2027.3%206.4%2041.3%207.4v-64.2c-7.8-.8-15.6-2.3-23.2-4.5l-40.8-12v-62c-13-3.8-25.8-8.8-38.2-15-8.2-4.1-16.9-7-25.8-8.8V164c-13-.4-26%20.8-38.7%203.6l-25.3%205.6V98l-64%2016v73.1zm256%20148.6c16.8%201.5%2033.9-.7%2050-6.8l14-5.2V252l-7.9%201.8c-18.4%204.3-37.3%205.7-56.1%204.5v77.4zm64-149.4v-70.8c-20.9%206.1-42.4%209.1-64%209.1V194c13.9%201.4%2028%20.5%2041.7-2.6l22.3-5.2z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M32%200c17.7%200%2032%2014.3%2032%2032v16l69-17.2c38.1-9.5%2078.3-5.1%20113.5%2012.5%2046.3%2023.2%20100.8%2023.2%20147.1%200l9.6-4.8c20.6-10.4%2044.8%204.6%2044.8%2027.6v279.7c0%2013.3-8.3%2025.3-20.8%2030l-34.7%2013c-46.2%2017.3-97.6%2014.6-141.7-7.4-37.9-19-81.3-23.7-122.5-13.4L64%20384v96c0%2017.7-14.3%2032-32%2032S0%20497.7%200%20480V32C0%2014.3%2014.3%200%2032%200zm32%20187.1%2064-13.9v65.5l-64%2013.9V318l48.8-12.2c5.1-1.3%2010.1-2.4%2015.2-3.3v-63.9l38.9-8.4c8.3-1.8%2016.7-2.5%2025.1-2.1v-64c13.6.4%2027.2%202.6%2040.4%206.4l23.6%206.9v66.7l-41.7-12.3c-7.3-2.1-14.8-3.4-22.3-3.8v71.4c21.8%201.9%2043.3%206.7%2064%2014.4V244l22.7%206.7c13.5%204%2027.3%206.4%2041.3%207.4v-64.2c-7.8-.8-15.6-2.3-23.2-4.5l-40.8-12v-62c-13-3.8-25.8-8.8-38.2-15-8.2-4.1-16.9-7-25.8-8.8V164c-13-.4-26%20.8-38.7%203.6l-25.3%205.6V98l-64%2016v73.1zm256%20148.6c16.8%201.5%2033.9-.7%2050-6.8l14-5.2V252l-7.9%201.8c-18.4%204.3-37.3%205.7-56.1%204.5v77.4zm64-149.4v-70.8c-20.9%206.1-42.4%209.1-64%209.1V194c13.9%201.4%2028%20.5%2041.7-2.6l22.3-5.2z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-certificate:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M211%207.3C205%201%20196-1.4%20187.6.8s-14.9%208.9-17.1%2017.3l-15.8%2062.5-62-17.5c-8.4-2.4-17.4%200-23.5%206.1s-8.5%2015.1-6.1%2023.5l17.5%2062-62.5%2015.9c-8.4%202.1-15%208.7-17.3%2017.1S1%20205%207.3%20211l46.2%2045-46.2%2045c-6.3%206-8.7%2015-6.5%2023.4s8.9%2014.9%2017.3%2017.1l62.5%2015.8-17.5%2062c-2.4%208.4%200%2017.4%206.1%2023.5s15.1%208.5%2023.5%206.1l62-17.5%2015.8%2062.5c2.1%208.4%208.7%2015%2017.1%2017.3s17.3-.2%2023.4-6.4l45-46.2%2045%2046.2c6.1%206.2%2015%208.7%2023.4%206.4s14.9-8.9%2017.1-17.3l15.8-62.5%2062%2017.5c8.4%202.4%2017.4%200%2023.5-6.1s8.5-15.1%206.1-23.5l-17.5-62%2062.5-15.8c8.4-2.1%2015-8.7%2017.3-17.1s-.2-17.4-6.4-23.4l-46.2-45%2046.2-45c6.2-6.1%208.7-15%206.4-23.4s-8.9-14.9-17.3-17.1l-62.5-15.8%2017.5-62c2.4-8.4%200-17.4-6.1-23.5s-15.1-8.5-23.5-6.1l-62%2017.5-15.9-62.5c-2.1-8.4-8.7-15-17.1-17.3S307%201%20301%207.3l-45%2046.2-45-46.2z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M211%207.3C205%201%20196-1.4%20187.6.8s-14.9%208.9-17.1%2017.3l-15.8%2062.5-62-17.5c-8.4-2.4-17.4%200-23.5%206.1s-8.5%2015.1-6.1%2023.5l17.5%2062-62.5%2015.9c-8.4%202.1-15%208.7-17.3%2017.1S1%20205%207.3%20211l46.2%2045-46.2%2045c-6.3%206-8.7%2015-6.5%2023.4s8.9%2014.9%2017.3%2017.1l62.5%2015.8-17.5%2062c-2.4%208.4%200%2017.4%206.1%2023.5s15.1%208.5%2023.5%206.1l62-17.5%2015.8%2062.5c2.1%208.4%208.7%2015%2017.1%2017.3s17.3-.2%2023.4-6.4l45-46.2%2045%2046.2c6.1%206.2%2015%208.7%2023.4%206.4s14.9-8.9%2017.1-17.3l15.8-62.5%2062%2017.5c8.4%202.4%2017.4%200%2023.5-6.1s8.5-15.1%206.1-23.5l-17.5-62%2062.5-15.8c8.4-2.1%2015-8.7%2017.3-17.1s-.2-17.4-6.4-23.4l-46.2-45%2046.2-45c6.2-6.1%208.7-15%206.4-23.4s-8.9-14.9-17.3-17.1l-62.5-15.8%2017.5-62c2.4-8.4%200-17.4-6.1-23.5s-15.1-8.5-23.5-6.1l-62%2017.5-15.9-62.5c-2.1-8.4-8.7-15-17.1-17.3S307%201%20301%207.3l-45%2046.2-45-46.2z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-book:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M96%200C43%200%200%2043%200%2096v320c0%2053%2043%2096%2096%2096h320c17.7%200%2032-14.3%2032-32s-14.3-32-32-32v-64c17.7%200%2032-14.3%2032-32V32c0-17.7-14.3-32-32-32H96zm0%20384h256v64H96c-17.7%200-32-14.3-32-32s14.3-32%2032-32zm32-240c0-8.8%207.2-16%2016-16h192c8.8%200%2016%207.2%2016%2016s-7.2%2016-16%2016H144c-8.8%200-16-7.2-16-16zm16%2048h192c8.8%200%2016%207.2%2016%2016s-7.2%2016-16%2016H144c-8.8%200-16-7.2-16-16s7.2-16%2016-16z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M96%200C43%200%200%2043%200%2096v320c0%2053%2043%2096%2096%2096h320c17.7%200%2032-14.3%2032-32s-14.3-32-32-32v-64c17.7%200%2032-14.3%2032-32V32c0-17.7-14.3-32-32-32H96zm0%20384h256v64H96c-17.7%200-32-14.3-32-32s14.3-32%2032-32zm32-240c0-8.8%207.2-16%2016-16h192c8.8%200%2016%207.2%2016%2016s-7.2%2016-16%2016H144c-8.8%200-16-7.2-16-16zm16%2048h192c8.8%200%2016%207.2%2016%2016s-7.2%2016-16%2016H144c-8.8%200-16-7.2-16-16s7.2-16%2016-16z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-scroll:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M0%2080v48c0%2017.7%2014.3%2032%2032%2032h64V80c0-26.5-21.5-48-48-48S0%2053.5%200%2080zm112-48c10%2013.4%2016%2030%2016%2048v304c0%2035.3%2028.7%2064%2064%2064s64-28.7%2064-64v-5.3c0-32.4%2026.3-58.7%2058.7-58.7H480V128c0-53-43-96-96-96H112zm352%20448c61.9%200%20112-50.1%20112-112%200-8.8-7.2-16-16-16H314.7c-14.7%200-26.7%2011.9-26.7%2026.7v5.3c0%2053-43%2096-96%2096h272z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M0%2080v48c0%2017.7%2014.3%2032%2032%2032h64V80c0-26.5-21.5-48-48-48S0%2053.5%200%2080zm112-48c10%2013.4%2016%2030%2016%2048v304c0%2035.3%2028.7%2064%2064%2064s64-28.7%2064-64v-5.3c0-32.4%2026.3-58.7%2058.7-58.7H480V128c0-53-43-96-96-96H112zm352%20448c61.9%200%20112-50.1%20112-112%200-8.8-7.2-16-16-16H314.7c-14.7%200-26.7%2011.9-26.7%2026.7v5.3c0%2053-43%2096-96%2096h272z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-bullhorn:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M480%2032c0-12.9-7.8-24.6-19.8-29.6S434.5.2%20425.3%209.3L381.7%2053c-48%2048-113.1%2075-181%2075H64c-35.3%200-64%2028.7-64%2064v96c0%2035.3%2028.7%2064%2064%2064v128c0%2017.7%2014.3%2032%2032%2032h64c17.7%200%2032-14.3%2032-32V352h8.7c67.9%200%20133%2027%20181%2075l43.6%2043.6c9.2%209.2%2022.9%2011.9%2034.9%206.9s19.8-16.6%2019.8-29.6V300.3c18.6-8.8%2032-32.5%2032-60.4s-13.4-51.6-32-60.4V32zm-64%2076.7v262.6C357.2%20317.8%20280.5%20288%20200.7%20288H192v-96h8.7c79.8%200%20156.5-29.8%20215.3-83.3z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M480%2032c0-12.9-7.8-24.6-19.8-29.6S434.5.2%20425.3%209.3L381.7%2053c-48%2048-113.1%2075-181%2075H64c-35.3%200-64%2028.7-64%2064v96c0%2035.3%2028.7%2064%2064%2064v128c0%2017.7%2014.3%2032%2032%2032h64c17.7%200%2032-14.3%2032-32V352h8.7c67.9%200%20133%2027%20181%2075l43.6%2043.6c9.2%209.2%2022.9%2011.9%2034.9%206.9s19.8-16.6%2019.8-29.6V300.3c18.6-8.8%2032-32.5%2032-60.4s-13.4-51.6-32-60.4V32zm-64%2076.7v262.6C357.2%20317.8%20280.5%20288%20200.7%20288H192v-96h8.7c79.8%200%20156.5-29.8%20215.3-83.3z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-crown:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M309%20106c11.4-7%2019-19.7%2019-34%200-22.1-17.9-40-40-40s-40%2017.9-40%2040c0%2014.4%207.6%2027%2019%2034l-57.3%20114.6c-9.1%2018.2-32.7%2023.4-48.6%2010.7L72%20160c5-6.7%208-15%208-24%200-22.1-17.9-40-40-40S0%20113.9%200%20136s17.9%2040%2040%2040h.7l45.7%20251.4c5.5%2030.4%2032%2052.6%2063%2052.6h277.2c30.9%200%2057.4-22.1%2063-52.6L535.3%20176h.7c22.1%200%2040-17.9%2040-40s-17.9-40-40-40-40%2017.9-40%2040c0%209%203%2017.3%208%2024l-89.1%2071.3c-15.9%2012.7-39.5%207.5-48.6-10.7L309%20106z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M309%20106c11.4-7%2019-19.7%2019-34%200-22.1-17.9-40-40-40s-40%2017.9-40%2040c0%2014.4%207.6%2027%2019%2034l-57.3%20114.6c-9.1%2018.2-32.7%2023.4-48.6%2010.7L72%20160c5-6.7%208-15%208-24%200-22.1-17.9-40-40-40S0%20113.9%200%20136s17.9%2040%2040%2040h.7l45.7%20251.4c5.5%2030.4%2032%2052.6%2063%2052.6h277.2c30.9%200%2057.4-22.1%2063-52.6L535.3%20176h.7c22.1%200%2040-17.9%2040-40s-17.9-40-40-40-40%2017.9-40%2040c0%209%203%2017.3%208%2024l-89.1%2071.3c-15.9%2012.7-39.5%207.5-48.6-10.7L309%20106z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-people-group:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M72%2088a56%2056%200%201%201%20112%200%2056%2056%200%201%201-112%200zm-8%20157.7c-10%2011.2-16%2026.1-16%2042.3s6%2031.1%2016%2042.3v-84.7zm144.4-49.3C178.7%20222.7%20160%20261.2%20160%20304c0%2034.3%2012%2065.8%2032%2090.5V416c0%2017.7-14.3%2032-32%2032H96c-17.7%200-32-14.3-32-32v-26.8C26.2%20371.2%200%20332.7%200%20288c0-61.9%2050.1-112%20112-112h32c24%200%2046.2%207.5%2064.4%2020.3zM448%20416v-21.5c20-24.7%2032-56.2%2032-90.5%200-42.8-18.7-81.3-48.4-107.7C449.8%20183.5%20472%20176%20496%20176h32c61.9%200%20112%2050.1%20112%20112%200%2044.7-26.2%2083.2-64%20101.2V416c0%2017.7-14.3%2032-32%2032h-64c-17.7%200-32-14.3-32-32zm8-328a56%2056%200%201%201%20112%200%2056%2056%200%201%201-112%200zm120%20157.7v84.7c10-11.3%2016-26.1%2016-42.3s-6-31.1-16-42.3zM320%2032a64%2064%200%201%201%200%20128%2064%2064%200%201%201%200-128zm-80%20272c0%2016.2%206%2031%2016%2042.3v-84.7c-10%2011.3-16%2026.1-16%2042.3zm144-42.3v84.7c10-11.3%2016-26.1%2016-42.3s-6-31.1-16-42.3zm64%2042.3c0%2044.7-26.2%2083.2-64%20101.2V448c0%2017.7-14.3%2032-32%2032h-64c-17.7%200-32-14.3-32-32v-42.8c-37.8-18-64-56.5-64-101.2%200-61.9%2050.1-112%20112-112h32c61.9%200%20112%2050.1%20112%20112z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M72%2088a56%2056%200%201%201%20112%200%2056%2056%200%201%201-112%200zm-8%20157.7c-10%2011.2-16%2026.1-16%2042.3s6%2031.1%2016%2042.3v-84.7zm144.4-49.3C178.7%20222.7%20160%20261.2%20160%20304c0%2034.3%2012%2065.8%2032%2090.5V416c0%2017.7-14.3%2032-32%2032H96c-17.7%200-32-14.3-32-32v-26.8C26.2%20371.2%200%20332.7%200%20288c0-61.9%2050.1-112%20112-112h32c24%200%2046.2%207.5%2064.4%2020.3zM448%20416v-21.5c20-24.7%2032-56.2%2032-90.5%200-42.8-18.7-81.3-48.4-107.7C449.8%20183.5%20472%20176%20496%20176h32c61.9%200%20112%2050.1%20112%20112%200%2044.7-26.2%2083.2-64%20101.2V416c0%2017.7-14.3%2032-32%2032h-64c-17.7%200-32-14.3-32-32zm8-328a56%2056%200%201%201%20112%200%2056%2056%200%201%201-112%200zm120%20157.7v84.7c10-11.3%2016-26.1%2016-42.3s-6-31.1-16-42.3zM320%2032a64%2064%200%201%201%200%20128%2064%2064%200%201%201%200-128zm-80%20272c0%2016.2%206%2031%2016%2042.3v-84.7c-10%2011.3-16%2026.1-16%2042.3zm144-42.3v84.7c10-11.3%2016-26.1%2016-42.3s-6-31.1-16-42.3zm64%2042.3c0%2044.7-26.2%2083.2-64%20101.2V448c0%2017.7-14.3%2032-32%2032h-64c-17.7%200-32-14.3-32-32v-42.8c-37.8-18-64-56.5-64-101.2%200-61.9%2050.1-112%20112-112h32c61.9%200%20112%2050.1%20112%20112z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-hand-point-up:before {
  transform: scaleX(-1);
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20384%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M32%2032C32%2014.3%2046.3%200%2064%200s32%2014.3%2032%2032v208H32V32zm192%20160c0-17.7%2014.3-32%2032-32s32%2014.3%2032%2032v64c0%2017.7-14.3%2032-32%2032s-32-14.3-32-32v-64zm-64-64c17.7%200%2032%2014.3%2032%2032v48c0%2017.7-14.3%2032-32%2032s-32-14.3-32-32v-48c0-17.7%2014.3-32%2032-32zm160%2096c0-17.7%2014.3-32%2032-32s32%2014.3%2032%2032v64c0%2017.7-14.3%2032-32%2032s-32-14.3-32-32v-64zm-96%2088v-.6c9.4%205.4%2020.3%208.6%2032%208.6%2013.2%200%2025.4-4%2035.6-10.8%208.7%2024.9%2032.5%2042.8%2060.4%2042.8%2011.7%200%2022.6-3.1%2032-8.6v8.6c0%2088.4-71.6%20160-160%20160h-61.7c-42.4%200-83.1-16.9-113.1-46.9l-11.7-11.6C13.5%20429.5%200%20396.9%200%20363v-27c0-35.3%2028.7-64%2064-64h88c22.1%200%2040%2017.9%2040%2040s-17.9%2040-40%2040H96c-8.8%200-16%207.2-16%2016s7.2%2016%2016%2016h56c39.8%200%2072-32.2%2072-72z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20384%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M32%2032C32%2014.3%2046.3%200%2064%200s32%2014.3%2032%2032v208H32V32zm192%20160c0-17.7%2014.3-32%2032-32s32%2014.3%2032%2032v64c0%2017.7-14.3%2032-32%2032s-32-14.3-32-32v-64zm-64-64c17.7%200%2032%2014.3%2032%2032v48c0%2017.7-14.3%2032-32%2032s-32-14.3-32-32v-48c0-17.7%2014.3-32%2032-32zm160%2096c0-17.7%2014.3-32%2032-32s32%2014.3%2032%2032v64c0%2017.7-14.3%2032-32%2032s-32-14.3-32-32v-64zm-96%2088v-.6c9.4%205.4%2020.3%208.6%2032%208.6%2013.2%200%2025.4-4%2035.6-10.8%208.7%2024.9%2032.5%2042.8%2060.4%2042.8%2011.7%200%2022.6-3.1%2032-8.6v8.6c0%2088.4-71.6%20160-160%20160h-61.7c-42.4%200-83.1-16.9-113.1-46.9l-11.7-11.6C13.5%20429.5%200%20396.9%200%20363v-27c0-35.3%2028.7-64%2064-64h88c22.1%200%2040%2017.9%2040%2040s-17.9%2040-40%2040H96c-8.8%200-16%207.2-16%2016s7.2%2016%2016%2016h56c39.8%200%2072-32.2%2072-72z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-person-military-pointing:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M246.9%2014.1C234%2015.2%20224%2026%20224%2039c0%2013.8%2011.2%2025%2025%2025h151c8.8%200%2016-7.2%2016-16V17.4c0-9.4-8-16.7-17.3-16L246.9%2014.1zM240%20112c0%2044.2%2035.8%2080%2080%2080s80-35.8%2080-80c0-5.5-.6-10.8-1.6-16H241.6c-1%205.2-1.6%2010.5-1.6%2016zM72%20224c-22.1%200-40%2017.9-40%2040s17.9%2040%2040%2040h152v89.4l162.8-162.9c-13.3-4.3-27.3-6.5-41.6-6.5H72zm345.7%2020.9L246.6%20416H416v-46.3l53.6%2090.6c11.2%2019%2035.8%2025.3%2054.8%2014.1s25.3-35.8%2014.1-54.8l-76.2-128.8c-11.2-18.9-26.6-34.5-44.6-45.9zM224%20448v32c0%2017.7%2014.3%2032%2032%2032h128c17.7%200%2032-14.3%2032-32v-32H224z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M246.9%2014.1C234%2015.2%20224%2026%20224%2039c0%2013.8%2011.2%2025%2025%2025h151c8.8%200%2016-7.2%2016-16V17.4c0-9.4-8-16.7-17.3-16L246.9%2014.1zM240%20112c0%2044.2%2035.8%2080%2080%2080s80-35.8%2080-80c0-5.5-.6-10.8-1.6-16H241.6c-1%205.2-1.6%2010.5-1.6%2016zM72%20224c-22.1%200-40%2017.9-40%2040s17.9%2040%2040%2040h152v89.4l162.8-162.9c-13.3-4.3-27.3-6.5-41.6-6.5H72zm345.7%2020.9L246.6%20416H416v-46.3l53.6%2090.6c11.2%2019%2035.8%2025.3%2054.8%2014.1s25.3-35.8%2014.1-54.8l-76.2-128.8c-11.2-18.9-26.6-34.5-44.6-45.9zM224%20448v32c0%2017.7%2014.3%2032%2032%2032h128c17.7%200%2032-14.3%2032-32v-32H224z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-globe:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M352%20256c0%2022.2-1.2%2043.6-3.3%2064H163.4c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6%203.3-64h185.3c2.2%2020.4%203.3%2041.8%203.3%2064zm28.8-64h123.1c5.3%2020.5%208.1%2041.9%208.1%2064s-2.8%2043.5-8.1%2064H380.8c2.1-20.6%203.2-42%203.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6%2078.3%2020.7%20142%2077.5%20171.9%20151.6zm-149.1%200H167.7c6.1-36.4%2015.5-68.6%2027-94.7%2010.5-23.6%2022.2-40.7%2033.5-51.5C239.4%203.2%20248.7%200%20256%200s16.6%203.2%2027.8%2013.8c11.3%2010.8%2023%2027.9%2033.5%2051.5%2011.6%2026%2020.9%2058.2%2027%2094.7zm-209%200H18.6c30-74.1%2093.6-130.9%20172-151.6-25.5%2034.2-45.3%2087.7-55.3%20151.6zM8.1%20192h123.1c-2.1%2020.6-3.2%2042-3.2%2064s1.1%2043.4%203.2%2064H8.1C2.8%20299.5%200%20278.1%200%20256s2.8-43.5%208.1-64zm186.6%20254.6c-11.6-26-20.9-58.2-27-94.6h176.6c-6.1%2036.4-15.5%2068.6-27%2094.6-10.5%2023.6-22.2%2040.7-33.5%2051.5-11.2%2010.7-20.5%2013.9-27.8%2013.9s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3%20352c10%2063.9%2029.8%20117.4%2055.3%20151.6-78.4-20.7-142-77.5-172-151.6h116.7zm358.1%200c-30%2074.1-93.6%20130.9-171.9%20151.6%2025.5-34.2%2045.2-87.7%2055.3-151.6h116.7z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M352%20256c0%2022.2-1.2%2043.6-3.3%2064H163.4c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6%203.3-64h185.3c2.2%2020.4%203.3%2041.8%203.3%2064zm28.8-64h123.1c5.3%2020.5%208.1%2041.9%208.1%2064s-2.8%2043.5-8.1%2064H380.8c2.1-20.6%203.2-42%203.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6%2078.3%2020.7%20142%2077.5%20171.9%20151.6zm-149.1%200H167.7c6.1-36.4%2015.5-68.6%2027-94.7%2010.5-23.6%2022.2-40.7%2033.5-51.5C239.4%203.2%20248.7%200%20256%200s16.6%203.2%2027.8%2013.8c11.3%2010.8%2023%2027.9%2033.5%2051.5%2011.6%2026%2020.9%2058.2%2027%2094.7zm-209%200H18.6c30-74.1%2093.6-130.9%20172-151.6-25.5%2034.2-45.3%2087.7-55.3%20151.6zM8.1%20192h123.1c-2.1%2020.6-3.2%2042-3.2%2064s1.1%2043.4%203.2%2064H8.1C2.8%20299.5%200%20278.1%200%20256s2.8-43.5%208.1-64zm186.6%20254.6c-11.6-26-20.9-58.2-27-94.6h176.6c-6.1%2036.4-15.5%2068.6-27%2094.6-10.5%2023.6-22.2%2040.7-33.5%2051.5-11.2%2010.7-20.5%2013.9-27.8%2013.9s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3%20352c10%2063.9%2029.8%20117.4%2055.3%20151.6-78.4-20.7-142-77.5-172-151.6h116.7zm358.1%200c-30%2074.1-93.6%20130.9-171.9%20151.6%2025.5-34.2%2045.2-87.7%2055.3-151.6h116.7z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-pen-nib:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22m368.4%2018.3-55.7%2055.8%20125.2%20125.2%2055.7-55.7c21.9-21.9%2021.9-57.3%200-79.2l-46-46.1c-21.9-21.9-57.3-21.9-79.2%200zM288%2094.6l-9.2%202.8-144.1%2043.2c-19.9%206-35.7%2021.2-42.3%2041L3.8%20445.8c-3.8%2011.3-1%2023.9%207.3%2032.4l153.6-153.5c-3-6.3-4.7-13.3-4.7-20.7%200-26.5%2021.5-48%2048-48s48%2021.5%2048%2048-21.5%2048-48%2048c-7.4%200-14.4-1.7-20.7-4.7L33.7%20500.9c8.6%208.3%2021.1%2011.2%2032.4%207.3l264.3-88.6c19.7-6.6%2035-22.4%2041-42.3l43.2-144.1%202.7-9.2L288%2094.6z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22m368.4%2018.3-55.7%2055.8%20125.2%20125.2%2055.7-55.7c21.9-21.9%2021.9-57.3%200-79.2l-46-46.1c-21.9-21.9-57.3-21.9-79.2%200zM288%2094.6l-9.2%202.8-144.1%2043.2c-19.9%206-35.7%2021.2-42.3%2041L3.8%20445.8c-3.8%2011.3-1%2023.9%207.3%2032.4l153.6-153.5c-3-6.3-4.7-13.3-4.7-20.7%200-26.5%2021.5-48%2048-48s48%2021.5%2048%2048-21.5%2048-48%2048c-7.4%200-14.4-1.7-20.7-4.7L33.7%20500.9c8.6%208.3%2021.1%2011.2%2032.4%207.3l264.3-88.6c19.7-6.6%2035-22.4%2041-42.3l43.2-144.1%202.7-9.2L288%2094.6z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bullet-user-pen:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M224%20256a128%20128%200%201%200%200-256%20128%20128%200%201%200%200%20256zm-45.7%2048C79.8%20304%200%20383.8%200%20482.3%200%20498.7%2013.3%20512%2029.7%20512h293.1c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3%208.6-21.5%2016.8-29.7l40.3-40.3c-32.1-31-75.7-50.1-123.9-50.1h-91.4zm435.5-68.3c-15.6-15.6-40.9-15.6-56.6%200l-29.4%2029.4%2071%2071%2029.4-29.4c15.6-15.6%2015.6-40.9%200-56.6l-14.4-14.4zM375.9%20417c-4.1%204.1-7%209.2-8.4%2014.9l-15%2060.1c-1.4%205.5.2%2011.2%204.2%2015.2s9.7%205.6%2015.2%204.2l60.1-15c5.6-1.4%2010.8-4.3%2014.9-8.4l129.2-129.3-71-71L375.9%20417z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%3E%3C%21--%21%20Font%20Awesome%20Free%206.6.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20%28Icons%3A%20CC%20BY%204.0%2C%20Fonts%3A%20SIL%20OFL%201.1%2C%20Code%3A%20MIT%20License%29%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M224%20256a128%20128%200%201%200%200-256%20128%20128%200%201%200%200%20256zm-45.7%2048C79.8%20304%200%20383.8%200%20482.3%200%20498.7%2013.3%20512%2029.7%20512h293.1c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3%208.6-21.5%2016.8-29.7l40.3-40.3c-32.1-31-75.7-50.1-123.9-50.1h-91.4zm435.5-68.3c-15.6-15.6-40.9-15.6-56.6%200l-29.4%2029.4%2071%2071%2029.4-29.4c15.6-15.6%2015.6-40.9%200-56.6l-14.4-14.4zM375.9%20417c-4.1%204.1-7%209.2-8.4%2014.9l-15%2060.1c-1.4%205.5.2%2011.2%204.2%2015.2s9.7%205.6%2015.2%204.2l60.1-15c5.6-1.4%2010.8-4.3%2014.9-8.4l129.2-129.3-71-71L375.9%20417z%22%2F%3E%3C%2Fsvg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

section.resume-section {
  align-items: center;
  max-width: 75rem;
  padding: 4rem 1rem;
  display: flex;
}

section.resume-section .resume-section-content {
  width: 100%;
}

section.resume-section .resume-section-content > ul {
  padding-inline-start: 48px;
}

@media (width >= 768px) {
  section.resume-section {
    min-height: 100vh;
    padding: 5rem 1rem;
  }
}

@media (width >= 992px) {
  section.resume-section {
    padding: 6rem 3rem;
  }
}

section.resume-section#about address {
  font-style: normal;
}

section.resume-section#about .bio {
  line-height: 1.25;
}

section.resume-section#about .bio p {
  color: #495057;
  margin-bottom: .45rem;
  font-size: 1.155rem;
  font-weight: 700;
}

section.resume-section#about .img-profile {
  border: .5rem solid #fd7e1433;
}

section.resume-section#about .subheading {
  font-size: calc(1.367rem + 1.404vw);
}

@media (width >= 1200px) {
  section.resume-section#about .subheading {
    font-size: 2.42rem;
  }
}

section.resume-section#about #mail {
  white-space: nowrap;
}

section.resume-section#about #mail-dropdown {
  text-transform: none;
  right: -7px;
}

section.resume-section#about #mail-dropdown .dropdown-item {
  cursor: pointer;
  padding-left: 8px;
  padding-right: 6px;
}

section.resume-section#about #mail-dropdown .dropdown-item .dropdown-pic {
  font-family: sans-serif;
}

section.resume-section#about #mail #mail-toast {
  z-index: 11;
}

@media (width <= 575.98px) {
  section.resume-section#about {
    padding-top: 3rem;
  }
}

@media (width >= 768px) {
  section.resume-section#about .bio {
    line-height: 1.5;
  }

  section.resume-section#about .bio p {
    margin-bottom: .65rem;
    font-size: calc(1.2515rem + .018vw);
  }
}

@media (width >= 768px) and (width >= 1200px) {
  section.resume-section#about .bio p {
    font-size: 1.265rem;
  }
}

@media (width >= 992px) {
  section.resume-section#about .bio {
    line-height: 1.65;
  }

  section.resume-section#about .bio p {
    margin-bottom: .85rem;
    font-size: calc(1.257rem + .084vw);
  }
}

@media (width >= 992px) and (width >= 1200px) {
  section.resume-section#about .bio p {
    font-size: 1.32rem;
  }
}

section.resume-section.text [title="Ongoing"] {
  cursor: help;
}

section.resume-section.text .subheading-minor, section.resume-section.text .subheading {
  margin-bottom: .5rem;
}

section.resume-section.text p:has( + ul) {
  margin-bottom: .2rem !important;
}

section.resume-section.text ul > li {
  padding-bottom: .1rem !important;
}

footer.resume-section .license > img {
  border: 1px solid #fff;
  height: 42px;
  transition: all .2s linear;
}

footer.resume-section .license > img:hover {
  transform: scale(1.1);
}

@media (width >= 992px) {
  footer.resume-section .license img:hover {
    transform: scale(1.1)translate(-4.25px, .5px);
  }
}
/*# sourceMappingURL=styles.7716a541.css.map */
